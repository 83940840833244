import _ from 'lodash';

export class OrdemServicoCriadaEmailModel {
  constructor({
    assunto,
    destinatario,
    emailResposta,
    html,
    remetente,
    tipoEmail,
    designJson
  }) {
    this.assunto = assunto;
    this.destinatario = destinatario;
    this.emailResposta = emailResposta;
    this.html = html;
    this.remetente = remetente;
    this.tipoEmail = tipoEmail;
    this.designJson = designJson ? JSON.parse(designJson) : null;

  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.designJson = retorno.designJson ? JSON.stringify(retorno.designJson) : null;
    retorno.html = retorno.html.replace(/@media/g, '@@media');

    return retorno;
  }
}