<template>
  <div>
    <div class="d-flex mb-4">
      <div class="titulo-pagina">
        {{ $t('modulos.ordem_servico.email_os_criada.titulo') }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row mb-2"
    >
      <input-text
        class="col-12 col-md-4"
        :label="$t('modulos.ordem_servico.email_os_criada.de')"
        :value="form.remetente"
        disabled
      />
      <input-text
        v-model="form.emailResposta"
        class="col-12 col-md-4"
        :label="$t('modulos.ordem_servico.email_os_criada.responder_para')"
      />
      <input-text
        v-model="form.destinatario"
        class="col-12 col-md-4"
        :label="$t('modulos.ordem_servico.email_os_criada.para')"
      />
      <input-text
        v-model="form.assunto"
        class="col-12"
        :label="$t('modulos.ordem_servico.email_os_criada.assunto')"
      />
    </v-form>
    <div
      id="template-email"
      class="template-email"
    >
      <EmailEditor
        ref="emailEditor"
        class="email-editor"
        :appearance="appearance"
        :min-height="minHeight"
        :project-id="projectId"
        :locale="locale"
        :tools="tools"
        :options="options"
        @load="buscarDadosEmail"
      />
    </div>

    <v-divider class="my-2" />
    <div class="d-flex mb-4">
      <div class="titulo-pagina">
        {{ $t('modulos.ordem_servico.email_os_criada.visualizar_email') }}
      </div>
    </div>
    <div
      ref="previewTemplate"
      v-html="form.html"
    />

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="naoEnviar"
      >
        <v-icon>$close</v-icon>
        {{ $t('modulos.ordem_servico.email_os_criada.nao_enviar_email') }}
      </botao-padrao>
      <botao-padrao
        class="mr-2"
        outlined
        @click="atualizarPreview"
      >
        <v-icon
          class="mr-1"
          size="20"
        >
          $mdiRefresh
        </v-icon>
        {{ $t('modulos.ordem_servico.email_os_criada.atualizar_email') }}
      </botao-padrao>
      <botao-padrao
        class="mr-2"
        @click="enviarEmailOsCriada"
      >
        <v-icon
          class="mr-1"
          size="20"
        >
          $mdiEmailFastOutline
        </v-icon>
        {{ $t('modulos.ordem_servico.email_os_criada.enviar_email') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import { OrdemServicoCriadaEmailModel } from '@/common/models/cadastros/OrdemServicoCriadaEmailModel.js';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import { EmailEditor } from 'vue-email-editor';
export default {
  name: 'OrdemServicoCriadaEmail',
  components: {
    EmailEditor,
  },
  props: ['id'],
  data() {
    return {
      form: new OrdemServicoCriadaEmailModel({}),
      editorId: 'template-email-editor',
      minHeight: '95vh',
      locale: 'pt-BR',
      projectId: 0,
      tools: {
        image: {
          enabled: true,
        },
      },

      options: {
        devices: ['desktop'],
      },
      appearance: {
        theme: 'dark',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      'Ordem de Serviço Criada'
    );
  },
  methods: {
    buscarDadosEmail: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.buscarDadosEmailOSCriada(this.id)
        .then((response) => {
          this.form = new OrdemServicoCriadaEmailModel(response.data);
          if (this.form.designJson)
            this.$refs.emailEditor.editor.loadDesign(this.form.designJson);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    enviarEmailOsCriada: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.$refs.emailEditor.editor.exportHtml((design) => {
        this.form.designJson = design.design;
        this.form.html = design.html;
      });
      OrdemServicoService.enviarEmailOSCriada(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t(
              'modulos.ordem_servico.email_os_criada.email_enviado_sucesso'
            )
          );
          this.$router.push({
            name: 'ordem-servico',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    atualizarPreview: function () {
      this.$refs.emailEditor.editor.exportHtml(async (design) => {
        const html = { html: design.html.replace(/@media/g, '@@media') };
        this.$store.dispatch('Layout/iniciarCarregamento');
        await OrdemServicoService.atualizarPreviewEmail(this.id, html)
          .then((response) => {
            this.form.html = response.data.html;
            this.$refs.previewTemplate.innerHTML = this.form.html;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    naoEnviar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: 'ordem-servico',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-email {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .email-editor {
    width: 100vw;
    height: 80vh;
    border-radius: 10px;
  }
}
</style>
